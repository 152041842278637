import * as React from "react";
import * as Yup from 'yup';

import Schedule from "@app/objects/Schedule";
import { Field, FieldProps, Formik, FormikProps } from "formik";
import { getInjuryStatusName, Injury, InjuryPendingReason, InjuryStatus } from "@app/objects/Injury";
import { connect } from "react-redux";
import { ActionCreatorsMapObject, bindActionCreators } from "redux";
import { ApplicationState } from "@app/store";

import FollowUpForm from "@app/components/Pages/Employees/InjuryStatusForm/FollowUpForm";
import ClosedForm from "@app/components/Pages/Employees/InjuryStatusForm/ClosedForm";
import { getSelectedInjuryJobsActionCreators } from "@app/store/InjuryJobs";

import { Loading } from "@app/components/UI/Loading";
import { User } from "@app/objects/User";
import Button from "@common/react/components/Forms/Button";


import * as PagesState from "@app/store/ItemList";
import { StatusHistory } from "@app/objects/StatusHistory";

import { request } from '@app/components/Api';

import PendingForm from './PendingForm';

import InjuryStatusHistory from "@app/components/Pages/Employees/InjuryStatusForm/InjuryStatusHistory";

import { getSelectedInjuryStatusHistoryActionCreators } from "@app/store/InjuryStatusHistory";
import { BaseParams } from "@common/react/objects/BaseParams";

const validationSchema = Yup.object().shape({
    injuryStatus: Yup.number().required(),
    injuryPendingReasonId: Yup.mixed()
        .when('injuryStatus', { is: InjuryStatus.Pending, then: Yup.number().min(1, 'You should set the number') })
});

interface OwnProps {
    injury: Injury;
    updateInjury: (oldInjury: Injury, newInjury: Injury) => void;
}

interface ReduxProps {
    jobs: PagesState.ItemsState<Schedule>;
    user: User | null;
    injuryPendingReasons: PagesState.ItemsState<InjuryPendingReason>;
    statusHistory: PagesState.ItemsState<StatusHistory>
}


type Props = OwnProps & ReduxProps & {
    injuryJobsActions: ActionCreatorsMapObject;
    injuryStatusHistoryActions: ActionCreatorsMapObject;
};


interface FormValues {
    injuryStatus: InjuryStatus;
    injuryPendingReasonId: number;
    note: string;
}

interface ComponentState {
    isLoading: boolean;
   
}

class InjuryStatusForm extends React.Component<Props, ComponentState> {
    count: number = 10;
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
           
        };

        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.onFormStatusSave = this.onFormStatusSave.bind(this);
        this.refreshStatuses = this.refreshStatuses.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    componentWillMount() {
        this.props.injuryJobsActions.reqPages('injuryJobs', 'scheduleList', 'schedule', { page: 1, objectId: this.props.injury.id, notClosed: true });
        this.props.injuryStatusHistoryActions.reqPages('injuryStatusHistory', 'statusHistoryList', 'statusHistory', this.statusHistoryListParams);
    }

    get statusHistoryListParams(): BaseParams {
        return {
            page: 1,
            withReason: true,
            withUser: true,
            withOutcome: true,
            withFollowUps: true,
            withSchedule: true,
            objectId: this.props.injury.id
        }
    }

    handleSubmitForm(values: FormValues) {
        this.setState({
            isLoading: true
        });

        const item = {
            id: -1,
            injuryStatus: +values.injuryStatus,
            injuryId: this.props.injury.id,

        };

        if (+values.injuryStatus === InjuryStatus.Pending) {
            (item as StatusHistory).pendingReasonId = +values.injuryPendingReasonId;
            (item as StatusHistory).note = values.note;
        }
       
        request<StatusHistory>('statusHistory', item).then((result: StatusHistory) => {
            this.setState({
                isLoading: false
            });


            if (result.injury) {

                this.props.updateInjury(this.props.injury, { ...this.props.injury, injuryStatus: result.injury.injuryStatus });
                this.refreshStatuses();
            }

        }).catch(() => {
            this.setState({
                isLoading: false
            });
        });
    }

    onFormStatusSave(result: StatusHistory) {
        debugger
        
        if (result.injury) {
            this.props.updateInjury(this.props.injury, { ...this.props.injury, injuryStatus: result.injury.injuryStatus });
            this.refreshStatuses();
        }
        debugger
    }

    refreshStatuses() {
        this.props.injuryStatusHistoryActions.refreshPages('injuryStatusHistory', 'statusHistoryList', this.statusHistoryListParams)
    }

    loadMore() {
        this.count += 10;
        this.props.injuryStatusHistoryActions.refreshPages('injuryStatusHistory', 'statusHistoryList', { ...this.statusHistoryListParams, count: this.count })
    }

    render() {
        const { injury, user, statusHistory } = this.props;

        if (injury) {
            const jobs = this.props.jobs ? this.props.jobs.items : [];

            const statuses = statusHistory ? statusHistory.items : [];

            const pagination = statusHistory ? statusHistory.pagination : 0;

            return <Formik
                initialValues={{
                    injuryStatus: injury.injuryStatus,
                    injuryPendingReasonId: -1,
                    note: ''
                    
                }}
                onSubmit={this.handleSubmitForm}
                enableReinitialize={true}
                validationSchema={validationSchema}
                render={(formikBag: FormikProps<FormValues>) => (
                    <div className="injury-status">
                        <div className="row">
                            <div className="col-sm-7">
                                <div className="row">
                                    <Field
                                        name="injuryStatus"
                                        children={({ field, form }: FieldProps<FormValues>) => (
                                            <div className={`form-group col-sm-6`}>
                                                <label htmlFor="injuryStatus">Status*</label>
                                                <div className="is-relative">
                                                    <select className="form-control" id="injuryStatus"  {...field} disabled={injury.injuryStatus === InjuryStatus.Closed}>
                                                        <option value={InjuryStatus.New}>{getInjuryStatusName(user, InjuryStatus.New)}</option>
                                                        <option value={InjuryStatus.InProgress}>{getInjuryStatusName(user, InjuryStatus.InProgress)}</option>
                                                        <option value={InjuryStatus.FollowUp}>{getInjuryStatusName(user, InjuryStatus.FollowUp)}</option>
                                                        <option value={InjuryStatus.Pending}>{getInjuryStatusName(user, InjuryStatus.Pending)}</option>
                                                        <option value={InjuryStatus.Closed}>{getInjuryStatusName(user, InjuryStatus.Closed)}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                    />

                                </div>

                                {injury.injuryStatus !== InjuryStatus.Closed && <React.Fragment>
                                    {+formikBag.values.injuryStatus === InjuryStatus.FollowUp && <FollowUpForm injury={injury} jobs={jobs} statusHistory={statuses[0] || null} onSave={this.refreshStatuses} />}

                                    {+formikBag.values.injuryStatus === InjuryStatus.Pending && injury.injuryStatus !== InjuryStatus.Pending && <PendingForm injury={injury} item={null} onSave={this.onFormStatusSave} updateInjury={this.props.updateInjury} />}

                                    {+formikBag.values.injuryStatus === InjuryStatus.Closed && <ClosedForm injury={injury} jobs={jobs} onSave={this.onFormStatusSave} />}

                                    {+formikBag.values.injuryStatus !== injury.injuryStatus && +formikBag.values.injuryStatus !== InjuryStatus.Closed && +formikBag.values.injuryStatus !== InjuryStatus.Pending && <div className="text-right">
                                        <Button className="btn btn-primary" type="button" onClick={e => { e.preventDefault(); e.stopPropagation(); formikBag.handleSubmit() }} isLoading={this.state.isLoading} title="Save">Save</Button>
                                    </div>}
                                </React.Fragment>}
                            </div>
                            <div className="col-sm-5 injury-status-history">
                                <h5><strong>Status History</strong></h5>
                                <InjuryStatusHistory injury={injury} statuses={statuses} updateInjury={this.props.updateInjury} />
                                <div className="text-center hide-print">
                                    {pagination && (pagination.total - pagination.offset > 10) && !(statuses.length % 10) && <Button type="button" className="btn btn-primary" onClick={this.loadMore}>Load More</Button>}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            />
        }

        return <Loading />
    }
}

export default connect(
    (state: ApplicationState, ownProps?: OwnProps) => {
        if (!ownProps || ownProps.injury.id <= 0) { return {}; }

        return {
            jobs: state.injuryJobs[ownProps.injury.id],
            user: state.login.user,
            statusHistory: state.injuryStatusHistory[ownProps.injury.id]
        };
    },
    (dispatch, ownProps?: OwnProps) => {
        if (!ownProps || ownProps.injury.id <= 0) { return {}; }

        return {
            injuryJobsActions: bindActionCreators(getSelectedInjuryJobsActionCreators(ownProps.injury.id), dispatch),
            injuryStatusHistoryActions: bindActionCreators(getSelectedInjuryStatusHistoryActionCreators(ownProps.injury.id), dispatch)
        }
    }
)(InjuryStatusForm as any);